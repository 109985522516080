import getRequest, { postRequest } from "../../Utils/api";

import React, { useState, useEffect, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import Footer from "../../Partials/Footer";

import Navbar from "../../Partials/Navbar";

import Sidebar from "../../Partials/Sidebar";

import { useParams } from "react-router-dom";

import { Box, Button } from "@mui/material";

import { ExportToCsv } from "export-to-csv";

import { useAlert } from "react-alert";

import { FaPowerOff } from "react-icons/fa";

const User_List = () => {
  const alert = useAlert();

  const specificDate = "2023-08-05";

  const navigate = useNavigate();

  const { status_code } = useParams();

  const LocalStorageData = JSON.parse(localStorage.getItem("loggedin"));

  const [getUserList, setGetUserList] = useState([]);

  const [loading, setLoading] = useState(false);
  const token = LocalStorageData?.generate_auth_token;
  useEffect(() => {
    setLoading(true);

    async function get_user_list() {
      const userListEndpoint = `/user_list/${status_code}`;
      const onBoardingEndpoint = "/on_boarding";
      const offBoardingEndpoint = "/off_boarding";

      try {
        const [userListResponse, onBoardingResponse, offBoardingResponse] =
          await Promise.all([
            getRequest(userListEndpoint, token),
            getRequest(onBoardingEndpoint, token),
            getRequest(offBoardingEndpoint, token),
          ]);

        if (
          userListResponse.success &&
          onBoardingResponse.success &&
          offBoardingResponse.success
        ) {
          const userListData = userListResponse.data;
          const onBoardingData = onBoardingResponse.data;
          const offBoardingData = offBoardingResponse.data;

          const pp = userListData.map((aa) => {
            const onBoardingItem = onBoardingData.find(
              (bb) => bb?.user_id === aa?._id
            );

            const offBoardingItem = offBoardingData.find(
              (bb) => bb?.employee_id === aa?._id
            );

            return {
              ...aa,
              ...onBoardingItem,
              ...offBoardingItem,
            };
          });

          setGetUserList(pp);
        } else {
          handleErrorResponse(
            userListResponse,
            onBoardingResponse,
            offBoardingResponse
          );
        }
      } catch (error) {
        handleErrorResponse(error);
      } finally {
        setLoading(false); // Move setLoading(false) inside the try-catch block
      }
    }

    const handleErrorResponse = (...responses) => {
      for (const response of responses) {
        if (
          response.error &&
          response.error.response &&
          response.error.response.status === 500
        ) {
          navigate("/error_500");
          return;
        }
      }

      navigate("/error_403");
    };

    get_user_list();
  }, [status_code]);

  const compareDates = (d1, d2) => {
    let date1 = new Date(d1);

    let date2 = new Date(d2);

    if (date1 < date2) {
      return false;
    } else if (date1 >= date2) {
      const s1 = new Date(date1.getTime() + 10 * 24 * 60 * 60 * 1000);

      const s2 = new Date(Date.now());

      if (s1 > s2) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const compareDates1 = (d1, d2) => {
    let date1 = new Date(d1);

    let date2 = new Date(d2);

    if (date1 < date2) {
      return false;
    } else {
      return true;
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row["First Name"]} ${row["Last Name"]}`, //accessorFn used to join multiple data into a single cell

        id: "name", //id is still required when using accessorFn instead of accessorKey

        header: "Name",

        size: 250,

        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",

              alignItems: "center",

              gap: "1rem",
            }}
          >
            <img
              alt="avatar"
              height={30}
              src={row.original.Photo}
              loading="lazy"
              style={{ borderRadius: "50%" }}
            />

            <span>{renderedCellValue}</span>

            <span
              className="badge badge-success"
              style={{
                borderRadius: "20px",

                display:
                  compareDates(
                    row.original?.creation_date?.split("T")[0],

                    specificDate
                  ) === true
                    ? "inline-block"
                    : "none",
              }}
            >
              New Joining
            </span>
          </Box>
        ),
      },

      {
        accessorKey: "Personal Mobile Number", //normal accessorKey

        header: "Phone",
      },

      {
        accessorKey: "Email address",

        header: "Email",
      },

      {
        accessorFn: (row) =>
          `${row["Acenet Role"] === "" ? "Team member" : row["Acenet Role"]} `,

        header: "Acenet Role",
      },

      {
        id: "onboarding",

        header: "Onboarding",

        columnDefType: "display", //turns off data column features like sorting, filtering, etc.

        enableColumnOrdering: true, //but you can turn back any of those features on if you want like this

        Cell: ({ row }) => {
          return (
            compareDates1(
              row.original.creation_date?.split("T")[0],

              specificDate
            ) === true && (
              <button
                type="button"
                className={`btn btn-sm ${
                  row.original?.hr_on_boarding_status === true &&
                  row.original?.finance_on_boarding_status === true
                    ? "btn-inverse-success"
                    : row.original?.initiate_on_boarding_status === true
                    ? "btn-inverse-danger"
                    : "btn-inverse-info"
                } ms-2`}
                title="Onboarding"
                onClick={() => {
                  const confirmationButton = window.confirm(
                    row.original.hr_on_boarding_status === true &&
                      row.original.finance_on_boarding_status === true
                      ? "Do you really want to check onboarding?"
                      : "Do you really want to initiate onboarding?"
                  );

                  if (confirmationButton === true) {
                    navigate(`/on_boarding/${row.original._id}`);
                  }
                }}
              >
                {row.original?.hr_on_boarding_status === true &&
                row.original?.finance_on_boarding_status === true
                  ? "Completed"
                  : row.original?.initiate_on_boarding_status === true
                  ? "Pending"
                  : "Initiate"}
              </button>
            )
          );
        },
      },

      {
        id: "offboarding",

        header: "Offboarding",

        columnDefType: "display", //turns off data column features like sorting, filtering, etc.

        enableColumnOrdering: true, //but you can turn back any of those features on if you want like this

        Cell: ({ row }) => (
          <button
            className={`btn btn-sm ${
              row.original?.hr_off_boarding_status === true &&
              row.original?.finance_off_boarding_status === true &&
              row.original?.management_off_boarding_status === true
                ? "btn-inverse-success"
                : row.original?.initiate_off_boarding_status === true
                ? "btn-inverse-danger"
                : "btn-inverse-info"
            } ms-2`}
            title="Offboarding"
            onClick={() => {
              const confirmationButton = window.confirm(
                row.original.hr_off_boarding_status === true &&
                  row.original.finance_off_boarding_status === true &&
                  row.original.management_off_boarding_status === true
                  ? "Do you really want to check resignation?"
                  : "Do you really want to initiate resignation?"
              );

              if (confirmationButton === true) {
                navigate(`/off_boarding/${row.original._id}`);
              }
            }}
          >
            {row.original?.hr_off_boarding_status === true &&
            row.original?.finance_off_boarding_status === true &&
            row.original?.management_off_boarding_status === true
              ? "Completed"
              : row.original?.initiate_off_boarding_status === true
              ? "Pending"
              : "Initiate"}
          </button>
        ),
      },
    ],

    []
  );

  const csvOptions = {
    fieldSeparator: ",",

    quoteStrings: '"',

    decimalSeparator: ".",

    showLabels: true,

    useBom: true,

    useKeysAsHeaders: false,

    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(getUserList);
  };

  const refreshData = () => {
    setLoading(true);

    postRequest("/compare_data_between_zoho_and_database", {
      data: "test",
    }).then((result) => {
      setLoading(false);

      if (result.success) {
        alert.success(result.data.message);
      } else {
        alert.error(result.error);
      }
    });
  };

  return (
    <>
      <div className="container-scroller">
        <Navbar />

        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div
              className="content-wrapper bg-light"
              style={{ borderRadius: "20px" }}
            >
              {loading && (
                <div class="loader-container">
                  <div class="loader">
                    <img
                      class="logo"
                      src="/assets/images/aceAppsLogo.png"
                      alt="Logo"
                    />
                  </div>
                </div>
              )}

              <div className="row">
                <div class="col-lg-12 grid-margin stretch-card">
                  <div className="card" style={{ borderRadius: "20px" }}>
                    <div
                      className="card-body"
                      style={{
                        maxWidth: "100%",

                        overflow: "hidden",

                        overflowX: "scroll",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span class="card-description">
                          {status_code === "active_employee"
                            ? "Employee List"
                            : status_code === "pending_onboarding_employee"
                            ? "Pending Onboarding Employee List"
                            : "Pending Offboarding Employee List"}
                        </span>

                        <div
                          className="btn btn-success mb-2"
                          onClick={() => refreshData()}
                        >
                          Refresh Zoho People
                        </div>
                      </div>

                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>#</th>

                            <th>Name</th>

                            <th>Phone</th>

                            <th>Email</th>

                            <th>Status</th>

                            <th>
                              {status_code === "pending_onboarding_employee"
                                ? "Onboarding"
                                : "Offboarding"}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {getUserList?.map((value, index) => {
                            return (
                              <tr key={index}>
                                <td className="py-1">
                                  <img src={value?.Photo} alt="image" />
                                </td>

                                <td>
                                  {value["First Name"]} {value["Last Name"]}
                                  <span
                                    className="badge badge-success"
                                    style={{
                                      borderRadius: "20px",

                                      display:
                                        compareDates(
                                          value?.creation_date?.split("T")[0],

                                          specificDate
                                        ) === true
                                          ? "inline-block"
                                          : "none",
                                    }}
                                  >
                                    New Joining
                                  </span>
                                </td>

                                <td>
                                  {value["Personal Mobile Number"] === ""
                                    ? "NA"
                                    : value["Personal Mobile Number"]}
                                </td>

                                <td> {value["Email address"]} </td>

                                <td>
                                  <>
                                    {compareDates1(
                                      value?.creation_date?.split("T")[0],

                                      specificDate
                                    ) === true && !value?.on_boarding_status ? (
                                      <label
                                        className={`text-danger fw-bold

                                         ms-2`}
                                      >
                                        Pending Onboarding
                                      </label>
                                    ) : (
                                      <>
                                        {value?.initiate_off_boarding_status ===
                                        false ? (
                                          <label
                                            className={`text-success fw-bold

                                             ms-2`}
                                          >
                                            Active
                                          </label>
                                        ) : value?.off_boarding_status ===
                                          true ? (
                                          <label
                                            className={`text-muted fw-bold

                                         ms-2`}
                                          >
                                            Deactive
                                          </label>
                                        ) : (
                                          <label
                                            className={`text-danger fw-bold

                                           ms-2`}
                                          >
                                            Pending Offboarding
                                          </label>
                                        )}
                                      </>
                                    )}
                                  </>

                                  {/* ========================================= */}
                                </td>

                                <td>
                                  {(status_code === "active_employee" ||
                                    status_code ===
                                      "pending_offboarding_employee") && (
                                    <>
                                      {(compareDates1(
                                        value?.creation_date?.split("T")[0],

                                        specificDate
                                      ) === false ||
                                        value?.on_boarding_status) && (
                                        <button
                                          type="button"
                                          style={{
                                            background: "rgb(209, 65, 36)",
                                          }}
                                          className={`btn btn-sm                                         } ms-2`}
                                          title="Offboarding"
                                          onClick={() => {
                                            const confirmationButton =
                                              !value?.initiate_off_boarding_status &&
                                              window.confirm(
                                                value?.offboarding_hr
                                                  ?.hr_off_boarding_status ===
                                                  true &&
                                                  value?.offboarding_finance
                                                    ?.finance_off_boarding_status ===
                                                    true &&
                                                  value?.offboarding_management
                                                    ?.management_off_boarding_status ===
                                                    true
                                                  ? "Do you really want to check offboarding?"
                                                  : "Do you really want to initiate offboarding?"
                                              );

                                            if (confirmationButton === true) {
                                              navigate(
                                                `/off_boarding/${value?._id}`
                                              );
                                            } else if (
                                              value?.initiate_off_boarding_status ===
                                              true
                                            ) {
                                              navigate(
                                                `/off_boarding/${value?._id}`
                                              );
                                            }
                                          }}
                                          disabled={
                                            value?.off_boarding_status
                                              ? true
                                              : false
                                          }
                                        >
                                          <FaPowerOff
                                            style={{
                                              color: "#fff",
                                            }}
                                          />
                                        </button>
                                      )}
                                    </>
                                  )}

                                  {/* ========== Action for Onboarding=============== */}

                                  {status_code ===
                                    "pending_onboarding_employee" &&
                                    compareDates1(
                                      value?.creation_date?.split("T")[0],

                                      specificDate
                                    ) === true && (
                                      <button
                                        type="button"
                                        className={`btn btn-sm btn-primary ms-2`}
                                        title="Onboarding"
                                        onClick={() => {
                                          navigate(
                                            `/on_boarding/${value?._id}`
                                          );
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-dots-vertical"
                                          title="Onboarding"
                                        ></i>
                                      </button>
                                    )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* ============= Modal =================== */}
            </div>

            <footer className="footer">
              <Footer />
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default User_List;
