import axios from "axios";
const LocalStorageData = JSON.parse(localStorage.getItem("loggedin"));

// Uncomment the line below if you are using environment variables

// import dotenv from "dotenv";

// dotenv.config();

// const baseUrl = "http://localhost:5502";
const baseUrl = process.env.REACT_APP_BASE_URL;

// Load data from local storage

// GET requestexport

async function signIn(endpoint, token) {
  try {
    const response = await axios.get(baseUrl + endpoint);

    return handleResponse(response);
  } catch (error) {
    return handleResponse(error.response);
  }
}
export default async function getRequest(endpoint, token) {
  try {
    const response = await axios.get(baseUrl + endpoint, {
      headers: { authorization: token },
    });

    return handleResponse(response);
  } catch (error) {
    return handleResponse(error.response);
  }
}

// POST request

async function postRequest(endpoint, data, token) {
  try {
    const response = await axios.post(baseUrl + endpoint, data, {
      headers: { authorization: token },
    });

    return handleResponse(response);
  } catch (error) {
    return handleResponse(error.response);
  }
}

// PUT Request

async function putRequest(endpoint, data, token) {
  try {
    const response = await axios.put(baseUrl + endpoint, data, {
      headers: { authorization: token },
    });

    return handleResponse(response);
  } catch (error) {
    return handleResponse(error.response);
  }
}

// DELETE Request

async function deleteRequest(endpoint, token) {
  try {
    const response = await axios.delete(baseUrl + endpoint, {
      headers: { authorization: token },
    });

    return handleResponse(response);
  } catch (error) {
    return handleResponse(error.response);
  }
}

// Handle response for both success and error cases

function handleResponse(response) {
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      return { success: true, data: response.data };
    } else {
      const errorMessage = `Request failed with status: ${response.status}`;

      // You can choose how to handle errors here, e.g., display a message on the UI

      return { success: false, message: errorMessage };
    }
  } else {
    const errorMessage = "Network error, please try again later.";

    // Handle network errors here

    return { success: false, message: errorMessage };
  }
}

export { postRequest, putRequest, deleteRequest, signIn };
