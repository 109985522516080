import React, { useEffect, useState } from "react";

import { useNavigate, Navigate } from "react-router-dom";

import { postRequest, signIn } from "../Utils/api";

import { useCookies } from "react-cookie";

import "./SignIn.css";
const Sign_In = () => {
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies([]);

  const [loading, setLoading] = useState(false);

  const onSignInZoho = async () => {
    setLoading(true);

    const endpoint = "/sign_in_zoho";

    const response = await signIn(endpoint);

    if (response.success) {
      window.location.replace(response.data);
    } else {
      console.error(response.error);
    }
  };

  useEffect(() => {
    async function sendCode() {
      setLoading(true);

      const urlParams = new URLSearchParams(window.location.search);

      const code = urlParams.get("code");

      if (code) {
        const endpoint = `/sign_in_zoho_get_access_token/${code}`;

        const response = await postRequest(endpoint);

        if (response.success) {
          localStorage.setItem("loggedin", JSON.stringify(response.data));

          navigate("/dashboard");
        } else {
          console.error(response.error);
        }
      }
      setLoading(false);
    }
    sendCode();
  }, [navigate]);

  if (localStorage.getItem("loggedin")) {
    return <Navigate to={"/dashboard"} />;
  }

  const myStyles = {
    backgroundImage: `url("../assets/login_bg.png")`,

    width: "100%",

    backgroundPosition: "right",

    height: "100%",

    backgroundSize: "cover",

    zIndex: 9999,

    display: "flex",

    minHeight: "100vh",

    width: "100vw",

    position: "absolute",

    justifyContent: "center",

    alignItems: "center",

    backgroundAttachment: "fixed",

    transition: "background-color 0.5s ease",
  };

  let date = new Date();

  return (
    <>
      <div class="row h-100 ">
        <div class="col-lg-6 col-12  d-flex justify-content-center align-items-center order-2 ">
          <div class="content text-center w-75">
            <img
              src="./assets/images/AceAppsTitle_new.svg"
              style={{ width: "100%", height: "100%" }}
            />

            <div class="pt-2"></div>

            <div class="sm-space"></div>

            <button
              className=" subscribe_button btn w-100 btn-lg btn-primary base-plus-font-size"
              onClick={onSignInZoho}
            >
              Sign In With ZOHO
            </button>

            <div class="sm-space"></div>

            <footer class="footer">
              <div class="credit-wrap text-center">
                <div class="credit sm-font-size text-dark-gray">.</div>

                <div class="copyright text-gray xs-font-size">
                  &copy; {date.getFullYear()}. All Rights Reserved.
                </div>
              </div>
            </footer>
          </div>
        </div>

        <div class="col-lg-6 col-12  align-items-end justify-content-center d-flex">
          <div class="row align-items-center justify-content-center">
            <div class="col-12 text-center">
              <img
                src="assets/images/team-work.jpg"
                alt="team work"
                className="img-fluid w-100 aside-img"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sign_In;
