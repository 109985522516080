import React, { useEffect, useState } from "react";
import getRequest from "../../Utils/api";
import Footer from "../../Partials/Footer";
import Navbar from "../../Partials/Navbar";
import Sidebar from "../../Partials/Sidebar";
import { useAlert } from "react-alert";

import { useNavigate } from "react-router-dom";
const TravelApprovalRequest = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [modalData, setModalData] = useState([]);
  const [id, setId] = useState("");
  const LocalStorageData = JSON.parse(localStorage.getItem("loggedin"));
  const [gettravelrequestdata, setGettravelrequestdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [getButtoncode, setButtoncode] = useState(
    "Pending Approval/Decline Request List"
  );

  // const [historyStatus, setHistoryStatus] = useState(false);
  useEffect(() => {
    async function getData() {
      setLoading(true);

      try {
        const response = await getRequest(
          "/all_travel_request",
          LocalStorageData?.generate_auth_token
        );

        if (response.success) {
          const resp = response.data;

          // Filter only the pending data
          const onlyPendingData = resp?.filter(
            (data) => data?.management_approval === "Pending"
          );

          setAllData(resp);

          // Set the original data and filtered data based on your requirements
          setOriginalData(resp);
          setGettravelrequestdata(
            LocalStorageData?.zoho_role === "Management"
              ? resp.filter(
                (x) =>
                  x?.created_by !== LocalStorageData?.email &&
                  x?.management_approval === "Pending"
              )
              : resp
          );
        } else {
          console.error("Error fetching data:", response.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);

        if (error.response?.status === 500) {
          navigate("/error_500");
        } else {
          navigate("/error_403");
        }
      }

      setLoading(false);
    }
    getData();
  }, []);

  const handleActiveClick = (e) => {
    e.preventDefault();
    const filteredRequest = originalData.filter(
      (x) => x.management_approval === "Pending"
    );
    setGettravelrequestdata(
      LocalStorageData?.zoho_role === "Management"
        ? allData.filter(
          (x) =>
            x.management_approval === "Pending" &&
            x?.created_by !== LocalStorageData?.email
        )
        : filteredRequest
    );
    setButtoncode("Pending Approval/Decline Request List");
  };
  const handleHistoryClick = (e) => {
    e.preventDefault();

    setGettravelrequestdata(
      LocalStorageData?.zoho_role === "Management" &&
      allData.filter((x) => x.management_approval === "Approved")
    );
    setButtoncode("History");
  };

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            {loading && (
              <div class="loader-container">
                <div class="loader">
                  <img
                    class="logo"
                    src="/assets/images/aceAppsLogo.png"
                    alt="Logo"
                  />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card" style={{ borderRadius: "20px" }}>
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <span class="card-description">{getButtoncode}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span class="card-description">TRAVEL REQUEST FORM</span>
                      <div className="d-flex justify-content-lg-end my-2 justify-content-center ">
                        <button
                          className="btn btn-sm btn-warning mx-2 "
                          onClick={handleActiveClick}
                        >
                          Pending
                        </button>
                        <button
                          className="btn btn-sm btn-success"
                          onClick={handleHistoryClick}
                        >
                          History
                        </button>
                      </div>
                    </div>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Requested by</th>
                          <th>Requested on</th>

                          <th>Action from Management</th>
                          <th>Remarks</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* =====All Request====== */}
                        {gettravelrequestdata
                          ?.slice() // Create a shallow copy to avoid modifying the original array
                          .reverse()
                          ?.map((val, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val?.created_by}</td>
                                <td>{val?.createdAt?.split("T")[0]}</td>

                                <td>
                                  <label
                                    class={`${val?.management_approval === "Approved"
                                        ? "text-success fw-bold"
                                        : val?.management_approval ===
                                          "Declined"
                                          ? "text-danger fw-bold"
                                          : "text-warning fw-bold"
                                      }`}
                                  >
                                    {val?.management_approval}
                                  </label>
                                </td>
                                <td>{val?.remarks}</td>
                                {getButtoncode === "History" ? (
                                  <td>
                                    <td
                                      className="btn btn-outline-primary btn-sm"
                                      type="button"
                                      onClick={() => {
                                        return (
                                          setModalData(val),
                                          setId(val._id),
                                          navigate(
                                            `/travelactionpage/${val._id}`
                                          )
                                        );
                                      }}
                                    >
                                      View
                                    </td>
                                  </td>
                                ) : (
                                  <td>
                                    <td
                                      className="btn btn-outline-primary btn-sm"
                                      type="button"
                                      onClick={() => {
                                        return (
                                          setModalData(val),
                                          setId(val._id),
                                          navigate(
                                            `/managementedittravel/${val._id}`
                                          )
                                        );
                                      }}
                                    >
                                      View
                                    </td>
                                  </td>
                                )}
                              </tr>
                            );
                          })}

                        {/* =======Pending Request===== */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer">
            <Footer />
          </footer>
        </div>
      </div>
    </div>
  );
};

export default TravelApprovalRequest;
