import React, { useEffect, useState } from "react";
import getRequest, { postRequest, putRequest } from "../../Utils/api";
import Footer from "../../Partials/Footer";
import Navbar from "../../Partials/Navbar";
import Page_Header from "../../Partials/Page_Header";
import Sidebar from "../../Partials/Sidebar";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { form_travel_request_action_validation } from "../../Utils/Validation_Form";
import classNames from "classnames";
import { CiEdit } from "react-icons/ci";

import moment from "moment-timezone";
import { useForm } from "react-hook-form";
moment().tz("Asia/Kolkata").format();
const Travel_Action = (props) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [status, setStatus] = useState("");

  const { _id } = useParams();
  const [loading, setLoading] = useState(false);
  const [getData, setGetData] = useState({});
  const [isManager, setIsManager] = useState(false);
  const [getTravellerDataEmail, setGetTravellerDataEmails] = useState([]);
  const [getRoomsData, setGetRoomsData] = useState([]);
  const [handleButtonType, setHandleButtonType] = useState();
  const LocalStorageData = JSON.parse(localStorage.getItem("loggedin"));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(form_travel_request_action_validation),
  });

  const [id, setId] = useState("");

  function formatBirthdate(birthdate) {
    var parts = birthdate.split("-");
    var formattedDate =
      parts[2].split("T")[0] + "-" + parts[1] + "-" + parts[0];

    return formattedDate;
  }
  useEffect(() => {
    const get_travel_request_by_id = async () => {
      setLoading(true);

      try {
        const response = await getRequest(
          `/get_travel_request_by_id/${_id}`,
          LocalStorageData?.generate_auth_token
        );

        if (response.success) {
          const res = response.data;

          setGetData(res);
          setGetTravellerDataEmails(res?.travellersData);
          setGetRoomsData(res?.roomsData);
          getEmailsFromData();
          setStatus(res?.management_approval);
        } else {
          console.error("Error fetching data:", response.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);

        if (error.response?.status === 500) {
          navigate("/error_500");
        } else {
          navigate("/error_403");
        }
      }

      setLoading(false);
    };

    get_travel_request_by_id();
  }, []);

  function getEmailsFromData(dataArray) {
    return dataArray?.map((item) => item?.email) || [];
  }

  // Combine and deduplicate email addresses
  const combinedEmails = [
    ...new Set([
      ...(getEmailsFromData(getTravellerDataEmail) || []),
      ...(getEmailsFromData(getRoomsData) || []),
    ]),
  ];

  // console.log("dadada", getEmailsFromData(getData?.roomsData));
  const onSubmitButton = async (e) => {
    // if (LocalStorageData?.zoho_role === "Management") {
    const confirmation = window.confirm(
      `Do you really want ${handleButtonType ? "Approve" : "Decline"
      } this request`
    );

    if (confirmation === true) {
      try {
        // Create a constant to store the data for the PUT request
        const requestData = {
          remarks: getData.remarks,
          managers_approval:
            getData?.managers_approval === "Pending"
              ? handleButtonType
                ? "Approved"
                : "Declined"
              : getData?.managers_approval,
          management_approval: handleButtonType ? "Approved" : "Declined",
        };

        const response = await putRequest(
          `/update_travel_request/${_id}`,
          requestData
        );

        if (response.data === "Updated Successfully") {
          // Make a POST request using the postRequest function
          await postRequest("/status_email", {
            status: handleButtonType ? "Approved" : "Declined",
            user: getData?.names,
            email: getData?.email,
            basicDetails: getData?.basicDetails,
            rows: getData?.rows ? getData?.rows : [],
            travellersData: getData?.travellersData
              ? getData?.travellersData
              : [],
            accommodationData: getData?.accommodationData
              ? getData?.accommodationData
              : [],
            roomsData: getData?.roomsData ? getData?.roomsData : [],
          });

          alert.show(
            `${handleButtonType ? "Approved" : "Declined"} Successfully`
          );
          navigate("/travelrequestreceived");
        }
      } catch (error) {
        console.error("Error updating travel request:", error);
      }
    }
  };

  function formatPhoneNumber(phoneNumber) {
    const cleanNumber = phoneNumber.replace(/\D/g, "");

    const numberWithout91 = cleanNumber.startsWith("91")
      ? cleanNumber.slice(2)
      : cleanNumber.startsWith("0")
        ? cleanNumber.slice(1)
        : cleanNumber;

    return "+91-" + numberWithout91;
  }

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            {loading && (
              <div class="loader-container">
                <div class="loader">
                  <img
                    class="logo"
                    src="/assets/images/aceAppsLogo.png"
                    alt="Logo"
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card" style={{ borderRadius: "20px" }}>
                  <div className="card-body">
                    <div
                      style={{
                        width: "100%",
                        border: "1px solid lightgrey",
                        padding: "1rem",
                        // marginTop: "1rem",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span class="card-description">TRAVEL REQUEST</span>
                        {status === "Pending" &&
                          getData?.created_by === LocalStorageData?.email && (
                            <td
                              className="btn btn-primary btn-sm"
                              type="button"
                              onClick={() => {
                                return (
                                  setId(_id),
                                  navigate(`/edittravelactionpage/${_id}`)
                                );
                              }}
                            >
                              <CiEdit />
                            </td>
                          )}
                        {LocalStorageData?.zoho_role === "Management" &&
                          status === "Pending" &&
                          getData?.created_by !== LocalStorageData?.email && (
                            <td
                              className="btn btn-primary btn-sm"
                              type="button"
                              onClick={() => {
                                return (
                                  setId(_id),
                                  navigate(`/managementedittravel/${_id}`)
                                );
                              }}
                            >
                              <CiEdit />
                            </td>
                          )}
                      </div>
                      <p class="box_title text-primary">Information</p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            {" "}
                            <th>Requested by</th>
                            <th>Billable</th>
                            <th>Client Name</th>
                            <th>Project Name</th>
                            <th>Reason For Travel</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{getData?.created_by}</td>

                            <td className="py-3">
                              {getData?.basicDetails?.billable}
                            </td>
                            <td className="py-3">
                              {getData?.basicDetails?.client_id}
                            </td>
                            <td>{getData?.basicDetails?.project_id}</td>
                            <td>{getData?.basicDetails?.reason_for_travel}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <>
                      <div
                        style={{
                          width: "100%",
                          border: "1px solid lightgrey",
                          padding: "1rem",
                          marginTop: "1rem",
                          display: `${getData?.travellersData?.length <= 0
                              ? "none"
                              : "block"
                            }`,
                        }}
                      >
                        {/* =================Travellers================================================ */}

                        {getData?.travellersData?.length > 0 && (
                          <>
                            <p class="box_title text-primary">Travellers</p>
                            <table className="table table-bordered my-4">
                              <thead>
                                <tr>
                                  <th className="w-25">Emp</th>
                                  <th className="w-25">Emp ID</th>
                                  <th className="w-25">Full Name</th>
                                  <th className="w-25">Gender</th>
                                  <th className="w-25">Phone</th>
                                  <th className="w-25">Email</th>
                                  <th className="w-25">DOB (MM-DD-YYYY)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {getData?.travellersData?.map((val) => {
                                  return (
                                    <tr>
                                      {" "}
                                      <td>{val?.data?.is_employee}</td>
                                      <td>{val?.data.emp_id?.value}</td>
                                      <td>{val?.data?.name}</td>
                                      <td>{val?.data?.gender}</td>
                                      <td>
                                        {formatPhoneNumber(val?.data?.phone) &&
                                          formatPhoneNumber(val?.data?.phone)}
                                      </td>
                                      <td>{val?.data?.email}</td>
                                      <td>
                                        {formatBirthdate(val?.data?.dob) &&
                                          formatBirthdate(val?.data?.dob)}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </>
                        )}

                        {/* =================Travel================================================ */}

                        {getData?.rows && (
                          <>
                            <p className="box_title text-primary">Travel</p>
                            <table className="table table-bordered my-4">
                              <thead>
                                <tr>
                                  <th className="w-25">Travel Mode</th>
                                  <th className="w-25">Trip Type</th>
                                  <th className="w-25">From City</th>
                                  <th className="w-25">To City</th>
                                  <th className="w-25">Departure Date</th>
                                  <th className="w-25">Return</th>
                                  <th className="w-25">Preferred Time</th>
                                  <th className="w-25">
                                    Return Preferred Time
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {getData?.rows?.map((val) => {
                                  const local_departure = moment
                                    .utc(val?.data?.departure)
                                    .local()
                                    .format("DD-MMM-YYYY");
                                  const local_return = moment
                                    .utc(val?.data?.return)
                                    .local()
                                    .format("DD-MMM-YYYY");
                                  return (
                                    <tr>
                                      {" "}
                                      <td>{val?.data?.travel_mode}</td>
                                      <td>{val?.data?.trip_type}</td>
                                      <td>
                                        {val?.data?.travel_from_city?.value}
                                      </td>
                                      <td>
                                        {val?.data?.travel_to_city?.value}
                                      </td>
                                      <td>
                                        {val?.data?.departure === undefined ||
                                          val?.data?.departure === "" ||
                                          val?.data?.departure === null
                                          ? "NA"
                                          : local_departure}
                                      </td>
                                      <td>
                                        {val?.data?.return === undefined ||
                                          val?.data?.return === "" ||
                                          val?.data?.return === null
                                          ? "NA"
                                          : local_return}
                                      </td>
                                      <td>
                                        <td>
                                          {val?.data?.preferred_time ===
                                            undefined
                                            ? "NA"
                                            : val?.data?.preferred_time}
                                        </td>
                                      </td>
                                      <td>
                                        <td>
                                          {val?.data?.return_preferred_time ===
                                            undefined
                                            ? "NA"
                                            : val?.data?.return_preferred_time}
                                        </td>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* =================Accomodation================================================ */}
                      <div
                        style={{
                          width: "100%",
                          border: "1px solid lightgrey",
                          padding: "1rem",
                          marginTop: "1rem",
                          display: `${getData?.accommodationData?.length <= 0
                              ? "none"
                              : "block"
                            }`,
                        }}
                      >
                        {getData?.accommodationData && (
                          <>
                            <p class="box_title text-primary">Accomodation</p>
                            <p>
                              Rooms Required :
                              <span className="fw-bold">
                                {" "}
                                {getData?.accommodationData[0]?.number_of_rooms}
                              </span>
                            </p>

                            <table className="table table-bordered my-4">
                              <thead>
                                <tr>
                                  <th>City</th>
                                  <th>Preferred Location</th>
                                  <th>Check-in</th>
                                  <th>Check-out</th>
                                  <th>Breakfast Required</th>
                                </tr>
                              </thead>
                              <tbody>
                                {getData?.accommodationData?.map((val) => {
                                  const local_checkIn = moment
                                    .utc(val?.data?.checkIn)
                                    .local()
                                    .format("DD-MMM-YYYY");
                                  const local_checkOut = moment
                                    .utc(val?.data?.checkOut)
                                    .local()
                                    .format("DD-MMM-YYYY");
                                  return (
                                    <tr>
                                      {" "}
                                      <td>{val?.data?.city?.value}</td>
                                      <td>{val?.data?.preferred_location}</td>
                                      <td>{local_checkIn && local_checkIn}</td>
                                      <td>
                                        {local_checkOut && local_checkOut}
                                      </td>
                                      <td>{val?.data?.breakfastRequired}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </>
                        )}

                        {/* =================Occupancy================================================ */}
                        {getData?.accommodationData && (
                          <>
                            <p class="box_title text-primary">Occupancy</p>
                            <table className="table table-bordered my-4">
                              <thead>
                                <tr>
                                  <th className="w-25">Emp</th>
                                  <th className="w-25">Emp ID</th>
                                  <th className="w-25">Full Name</th>
                                  <th className="w-25">Gender</th>
                                  <th className="w-25">Phone</th>
                                  <th className="w-25">Email</th>
                                  <th className="w-25">DOB (MM-DD-YYYY) </th>
                                </tr>
                              </thead>
                              <tbody>
                                {getData?.roomsData?.map((val) => {
                                  const parsedDOB = moment(
                                    val?.data?.dob,
                                    "MM-DD-YYYY"
                                  );
                                  const local_dob =
                                    parsedDOB.format("DD-MMM-YYYY");

                                  return (
                                    <tr>
                                      {" "}
                                      <td>{val?.data?.is_employee}</td>
                                      <td>{val?.data?.emp_id?.value}</td>
                                      <td>{val?.data?.name}</td>
                                      <td>{val?.data?.gender}</td>
                                      <td>
                                        {formatPhoneNumber(val?.data?.phone) &&
                                          formatPhoneNumber(val?.data?.phone)}
                                      </td>
                                      <td>{val?.data?.email}</td>
                                      <td>
                                        {formatBirthdate(val?.data?.dob) &&
                                          formatBirthdate(val?.data?.dob)}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                    </>
                    {getData?.basicDetails?.special_request !== "" && (
                      <div
                        style={{
                          width: "100%",
                          border: "1px solid lightgrey",
                          padding: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                        <p class="box_title text-primary">Special Request</p>
                        <blockquote class="blockquote">
                          <p class="mb-0">
                            {getData?.basicDetails?.special_request}
                          </p>
                        </blockquote>
                      </div>
                    )}
                    {LocalStorageData?.zoho_role === "Management" &&
                      getData?.created_by !== LocalStorageData?.email &&
                      getData?.management_approval === "Pending" ? (
                      <form
                        className="forms-sample"
                        onSubmit={handleSubmit(onSubmitButton)}
                      >
                        <div class="col-12 mt-2">
                          <div class="form-group">
                            <label>Remarks</label>
                            <textarea
                              name="remarks"
                              className={classNames(
                                "form-control form-control-sm font-bold",
                                {
                                  "is-invalid": errors.remarks,
                                }
                              )}
                              {...register("remarks", {
                                value: getData?.remarks,
                              })}
                              onChange={(e) =>
                                setGetData({
                                  ...getData,
                                  remarks: e.target.value,
                                })
                              }
                              value={getData?.remarks}
                              placeholder="Enter Remarks"
                              rows={4}
                            ></textarea>
                          </div>
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-sm btn-gradient-success me-2"
                            onClick={() => setHandleButtonType(true)}
                          >
                            Approve
                          </button>
                          <button
                            type="submit"
                            className="btn btn-sm btn-gradient-danger me-2"
                            onClick={() => setHandleButtonType(false)}
                          >
                            Decline
                          </button>
                        </div>
                      </form>
                    ) : (
                      <div className="text-center mt-3 ">
                        <button
                          className={
                            getData?.management_approval === "Approved"
                              ? "btn bg-light btn-success text-dark text-center fs-4"
                              : getData?.management_approval === "Pending"
                                ? " btn bg-light btn-warning text-dark text-center fs-4"
                                : "btn  bg-light btn-danger text-dark text-center fs-4"
                          }
                          disabled
                          style={{
                            borderRadius: "30px",
                          }}
                        >
                          {" "}
                          Status: {` ${getData?.management_approval} `}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <Footer />
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Travel_Action;
