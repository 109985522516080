import React, { useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import Footer from "../Partials/Footer";

import Navbar from "../Partials/Navbar";
import Sidebar from "../Partials/Sidebar";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { BsFillPeopleFill } from "react-icons/bs";

import { FaWpforms, FaLuggageCart, FaDoorOpen } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";

const Dashboard = () => {
  const navigate = useNavigate();

  const LocalStorageData = JSON.parse(localStorage.getItem("loggedin"));

  const [roless, setRoless] = useState();

  const [loading, setLoading] = useState(false);

  const toaster = () => {
    const messageDisplayed = localStorage.getItem("messageDisplayed");

    if (!messageDisplayed) {
      toast.success(`Welcome ${LocalStorageData?.name}!`);

      localStorage.setItem("messageDisplayed", true);
    }
  };

  toaster();

  return (
    <div className="container-scroller">
      <Navbar />

      <div className="container-fluid page-body-wrapper">
        <Sidebar />

        <div className="main-panel">
          <div className="content-wrapper" style={{ borderRadius: "20px" }}>
            {loading && (
              <div class="loader-container">
                <div class="loader">
                  <img class="logo" src="/AceAppsTitle.png" alt="Logo" />
                </div>
              </div>
            )}

            <div className="row ">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card" style={{ borderRadius: "20px" }}>
                  <div
                    className="card-body vh-100 d-flex align-items-start flex-column "
                    style={{ minHeight: "100%" }}
                  >
                    <>
                      <div className="text-start">
                        <h5 class="">
                          {/* <img
                              src={LocalStorageData?.photo}
                              alt=""
                              srcset=""
                              style={{ borderRadius: "50%" }}
                            /> */}
                          <br />
                          <span> Welcome back,</span>

                          <span className="fw-bold fs-4">
                            {" "}
                            {LocalStorageData?.owner_name}!
                          </span>
                        </h5>
                      </div>
                      {LocalStorageData?.zoho_role === "Accounts" ? (
                        <div className="col-md-4 col-12">
                          <div className="dashboard_sub_cards">
                            <FaDoorOpen className="fs-3 mx-3" />

                            <NavLink
                              className="nav-link "
                              to="/accounts_expense"
                            >
                              <h4>All Expense Request</h4>
                            </NavLink>
                          </div>
                        </div>
                      ) : (
                        <div className="row  w-100">
                          <div className="col-12">
                            <div className="row gy-2">
                              <div className="col-md-4 col-12 ">
                                <div className="dashboard_sub_cards">
                                  <FaWpforms className="fs-3 mx-3 " />
                                  <NavLink className="nav-link " to="/form12bb">
                                    <h4>Forms</h4>
                                  </NavLink>
                                </div>
                              </div>
                              <div className="col-md-4 col-12  ">
                                <div className="dashboard_sub_cards">
                                  <FaLuggageCart className="fs-3 mx-3" />

                                  <NavLink
                                    className="nav-link "
                                    to="/travelrequestform"
                                  >
                                    <h4>Travel Request</h4>
                                  </NavLink>
                                </div>
                              </div>
                              <div className="col-md-4 col-12 ">
                                <div className="dashboard_sub_cards">
                                  <FaDoorOpen className="fs-3 mx-3" />

                                  <NavLink
                                    className="nav-link "
                                    to="/cabin_slot_booking"
                                  >
                                    <h4>Cabin Booking</h4>
                                  </NavLink>
                                </div>
                              </div>
                              <div className="col-md-4 col-12">
                                <div className="dashboard_sub_cards">
                                  <FaDoorOpen className="fs-3 mx-3" />

                                  <NavLink
                                    className="nav-link "
                                    to="/create_expense"
                                  >
                                    <h4>Expense Request</h4>
                                  </NavLink>
                                </div>
                              </div>

                              {LocalStorageData?.zoho_role !==
                                "Team member" && (
                                <div className="col-md-4 col-12">
                                  <div className="dashboard_sub_cards">
                                    <BsFillPeopleFill className="fs-3 mx-3" />

                                    <NavLink
                                      className="nav-link "
                                      to="/user_list/active_employee"
                                    >
                                      <h4>Employee List</h4>
                                    </NavLink>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ========View Request Modal=========== */}

          <footer className="footer">
            <Footer />
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
